import { Lang } from 'Roblox';
import angular from 'angular';

const notificationStream = angular
  .module('notificationStream', [
    'robloxApp',
    'ui.bootstrap',
    'notificationStreamHtmlTemplate',
    'thumbnails'
  ])
  .config([
    'languageResourceProvider',
    function (languageResourceProvider) {
      const notificationStreamResources = Lang.NotificationStreamResources;
      // Temporary until translation strings are added
      const translationConstants = {
        'Message.GroupJoinRequestAcceptedSingle':
          (notificationStreamResources &&
            notificationStreamResources['Message.GroupJoinRequestAcceptedSingle']) ||
          'Your request to join {groupOne} has been accepted.',
        'Message.GroupJoinRequestAcceptedDouble':
          (notificationStreamResources &&
            notificationStreamResources['Message.GroupJoinRequestAcceptedDouble']) ||
          'Your requests to join {groupOne} and {groupTwo} have been accepted.',
        'Message.GroupJoinRequestAcceptedMultiple':
          (notificationStreamResources &&
            notificationStreamResources['Message.GroupJoinRequestAcceptedMultiple']) ||
          'Your requests to join {groupOne}, {groupTwo}, and {groupMultipleCount, plural, =1 {# other} other {# others}} have been accepted.'
      };

      if (Lang.NotificationStreamResources) {
        languageResourceProvider.setLanguageKeysFromFile(notificationStreamResources);
        languageResourceProvider.setLanguageKeysFromFile(translationConstants);
      }
    }
  ]);

export default notificationStream;
