import notificationStreamIconModule from '../notificationStreamIconModule';

function notificationStreamIndicator(
  notificationStreamUtility,
  $document,
  $log,
  notificationStreamService,
  eventStreamService
) {
  'ngInject';

  return {
    restrict: 'A',
    replace: true,
    scope: true,
    templateUrl: notificationStreamUtility.templates.notificationStreamIndicatorTemplate,
    link(scope, element, attrs) {
      scope.layout = scope.layout || {};
      scope.getUnreadNotificationCount = function () {
        notificationStreamService
          .unreadCount()
          .then(
            function (data) {
              if (data) {
                const { unreadNotifications } = data;

                if (unreadNotifications > 0) {
                  // analytics event (indicating notifications unopened but
                  // unread count shown to users)
                  eventStreamService.sendEventWithTarget(
                    eventStreamService.eventNames.notificationStream.openCTA,
                    eventStreamService.context.seen,
                    { count: unreadNotifications, sendrVersion: 0 }
                  );
                }

                scope.layout.unreadNotifications = unreadNotifications;
              }
            },
            function () {
              $log.debug(
                '--- unreadCount call failed from notificationStreamIndicatorDirective----- '
              );
            }
          )
          .catch(e => {
            $log.error(e);
          });
      };

      function updateLayout(data) {
        scope.layout.unreadNotifications = data.count;
        scope.layout.isNotificationContentOpen = data.isNotificationContentOpen;
      }

      $document.bind('Roblox.NotificationStream.UnreadNotifications', function (event, args) {
        $log.debug(` ----- notificationStreamIconController --- args.count --------${args.count}`);
        scope.$evalAsync(updateLayout(args));
      });

      $document.bind('Roblox.NotificationStream.ClearUnreadNotifications', () => {
        if (scope.layout.unreadNotifications > 0) {
          updateLayout({ count: 0, isNotificationContentOpen: true });
          notificationStreamService.clearUnread().then(() => {
            scope.layout.unreadNotifications = 0;
          });
        }
      });

      if (!scope.layout || !scope.layout.isNotificationContentOpen) {
        scope.getUnreadNotificationCount();
      }
    }
  };
}

notificationStreamIconModule.directive('notificationStreamIndicator', notificationStreamIndicator);

export default notificationStreamIndicator;
